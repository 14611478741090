@import url('https://use.typekit.net/pov2llv.css');
@import '@splidejs/splide-extension-video/dist/css/splide-extension-video.min.css';
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
    font-family: 'icomoon';
    src: url("icomoon/fonts/icomoon.eot?azzu2x");
    src: url("icomoon/fonts/icomoon.eot?azzu2x#iefix") format('embedded-opentype'), url("icomoon/fonts/icomoon.ttf?azzu2x") format('truetype'), url("icomoon/fonts/icomoon.woff?azzu2x") format('woff'), url("icomoon/fonts/icomoon.svg?azzu2x#icomoon") format('svg');
    font-weight: normal;
    font-style: normal;
}
[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.icon-museum:before {
    content: "\e900";
}
.icon-robot:before {
    content: "\e901";
}
.icon-logomark:before {
    content: "\e902";
}
.icon-codex:before {
    content: "\e903";
}
.icon-machines:before {
    content: "\e91c";
}
.icon-sundries:before {
    content: "\e91d";
}
.icon-photographica:before {
    content: "\e91e";
}
.icon-photo-equipment:before {
    content: "\e91e";
}
.icon-services:before {
    content: "\e91f";
}
.icon-film-and-materials:before {
    content: "\e91f";
}
.icon-instagram:before {
    content: "\e920";
}
.icon-facebook:before {
    content: "\e921";
}
.icon-twitter:before {
    content: "\e922";
}
[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.rich-text-container p {
    margin-bottom: 2rem;
}

.rich-text-container ul {
    list-style: disc !important;
    margin-left: 1.5rem;
}

.rich-text-container ul li {
    list-style: disc !important;
    margin-left: 1.5rem;
    margin-bottom: 1rem;
}

.rich-text-container ul.nav {
    list-style: none !important;
    margin-left:0;
    margin-bottom: 0;
    padding: 4px;
}

.rich-text-container ul.nav li {
    list-style: none !important;
    margin-left:0;
    margin-bottom: 0;
}


.rich-text-container a {
    color: #006AB9 !important;
}

.rich-text-container a:hover {
    text-decoration: underline !important;
}

.active {
    background-color: rgb(246 246 246)
}

.filter-white {
    filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(1deg) brightness(103%) contrast(102%);
}

.expand-body {
    min-height:70vh !important;
}

.accordion-button {
    color: #192B37 !important;
}

[x-cloak] {
    display: none !important;
}

/*input[type="search"].my-search {*/
/*    -webkit-appearance: searchfield;*/
/*}*/

/*input[type="search"].my-search::-webkit-search-cancel-button {*/
/*    -webkit-appearance: searchfield-cancel-button;*/
/*}*/

input[type="search"]::-webkit-search-cancel-button {

    /* Remove default */
    -webkit-appearance: none;

    /* Now your own custom styles */
    height: 14px;
    width: 14px;
    display: block;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAn0lEQVR42u3UMQrDMBBEUZ9WfQqDmm22EaTyjRMHAlM5K+Y7lb0wnUZPIKHlnutOa+25Z4D++MRBX98MD1V/trSppLKHqj9TTBWKcoUqffbUcbBBEhTjBOV4ja4l4OIAZThEOV6jHO8ARXD+gPPvKMABinGOrnu6gTNUawrcQKNCAQ7QeTxORzle3+sDfjJpPCqhJh7GixZq4rHcc9l5A9qZ+WeBhgEuAAAAAElFTkSuQmCC);
    /* setup all the background tweaks for our custom icon */
    background-repeat: no-repeat;

    /* icon size */
    background-size: 14px;

}

.form-switch:checked {
    background-position: right -8px center;
    background-image: none !important;
}

.container  {
    margin-top: 0px !important;
}

.container-extra {
    padding-top: 75px !important;
}

#content-wrapper a {
    color: #006AB9;
}

#content-wrapper a:hover {
    text-decoration: underline;
}

[x-cloak] { display: none !important; }

.content-wrap p {
    margin-bottom: 1.5rem !important;
}

.form-styles input,
.form-styles textarea {
    width: 100% !important;
}

@media (max-width: 1080px)
{
    .help-card {
        top: 50px !important;
    }
}

#robot-lib .big-button .image {
    margin: auto !important;
}

.autoSaverSwitch input:checked ~ .slider .dot {
    --tw-translate-x: 1.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.autoSaverSwitch input:checked ~ .slider .dot {
    transform: translateX(24px);
}
.duration-200 {
    transition-duration: 200ms;
}
.inline-form-content {
    width: 100% !important;
    max-width: 100% !important;
}
._form._form_7 {
    margin-top: 0 !important;
}

@media only screen and (max-width: 1280px) {
    #_form_67ED6CDDAB036_ > div._form-content.inline-form-content > div._button-wrapper._inline-style {
        margin-left: 0 !important;
        display:block !important;
        float: none !important;
        clear: both !important;
    }
}

@media only screen and (max-width: 600px) {
    ._form._form_7 {
        margin-left: 10px !important;
    }

}
